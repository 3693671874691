<template>
	<v-container
		fluid
		class="d-flex align-content-center justify-center flex-wrap background fill-height"
	>
		<v-card
			height="80%"
			width="80%"
		>
			<v-row class="mt-0 fill-height">
				<v-col
					cols="6"
					class="py-0"
				>
					<v-card
						flat
						class="pt-3 fill-height"
					>
						<v-img
							class="ml-4"
							max-width="150"
							src="../assets/cassie-logo-black.svg"
						/>
						<div
							class="d-flex align-content-center justify-center flex-wrap cassie-login-form-container"
						>
							<v-row>
								<v-spacer />
								<v-col
									cols="8"
									class="text-center"
								>
									<v-card-title class="text-h4 justify-center my-5 cassie-word-break">
										{{ formTitle }}
									</v-card-title>
									<slot name="form" />
								</v-col>
								<v-spacer />
							</v-row>
						</div>
					</v-card>
				</v-col>
				<v-col
					cols="6"
					class="py-0"
				>
					<div
						class="d-flex align-content-center justify-center flex-wrap fill-height white--text cassie-login-portal-background"
					>
						<span class="text-h4">
							{{ portalName }}
						</span>
					</div>
				</v-col>
			</v-row>
		</v-card>
	</v-container>
</template>

<script>
export default {
	props: {
		formTitle: {
			type: String,
			default: ''
		},
		portalName: {
			type: String,
			default: ''
		}
	}
}
</script>

<style lang="scss">
.cassie-login-form-container {
  height: 80%;
}

.cassie-login-portal-background{
  background: var(--global-color-nav-background);
}
</style>
